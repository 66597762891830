import './App.css';
import { ReactTagManager } from 'react-gtm-ts';

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}

function App() {
  ReactTagManager.init(tagManagerArgs)
  const token = new URLSearchParams(window.location.search).get('token');
  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=fb63342b-349b-4594-8877-3d7a2f84065c&redirectUrl=https://certifiedcode.wixsite.com/wishlist/_functions/@certifiedcode/base-backend/auth`;
  }

  return <></>
}

export default App;
